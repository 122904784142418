:root {
  --primary-blue: #011746;
  --secondary-blue: #01176e;
  --yellow: #FDCB2E;
  --white: #FFFFFF;
  --black: #000000;
}


body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

