.filter-desktop {
    display: none;
}

.filter-mobile {
    display: flex;
}

@media(min-width: 1280px) {
    .filter-desktop {
        list-style: none;
        display: flex;
        width: 40vw;
        justify-content: space-between;
        color: blue;
    }
        
    .filter-mobile {
        display: none;
    }
}