.profile-general-informations{
    display: flex;
    flex-direction: column;  
    align-items: center;
    border-right: 0.5px solid gray;
    padding: 1rem 2rem;
}

.profile-general-informations img{
    width: 5vw;
    height: 5vw;
    border-radius: 100%;
}

.profile-general-informations #data-user{
    list-style: none;
}

#data-user li{
    display: flex;
}

#data-user li p{
    margin-left: .5vw;
}

.profile-general-informations #actions{
    display: flex;
    flex-direction: row;
}

.profile-user-actions{
    display: flex;
    margin-top: 1rem;
}

.profile-user-actions button{
    display: flex;
    flex-direction: column; 
    align-items: center;
    background: none;
    border: none;   
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.profile-user-actions button:hover{
    background-color: rgba(0, 0, 0, .25);
}

.device-quantity-profile-informations{
    padding: 1rem;
}
.device-quantity-profile-informations p{
    font-size: 4rem;
    font-weight: 600;
}