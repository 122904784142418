.message-container {
    flex-direction: row;
}

.user-list-mobile {
    display: none;
}

.user-list {
    min-height: 85vh;
    min-width: 20vw;
    border-right: 1px solid rgba(0, 0, 0, .15);
    background-color: rgb(203, 203, 255);
    padding: 0;
}

.user-list ul {
    padding: 0;
}

.user-list input {
    padding-left: 1rem;
}

.header-chat {
    background-color: var(--primary-blue);
    padding: 1rem;
    display: flex;
    width: 60vw;
    color: var(--white);
}

.add-chat {
    border-radius: 50%;
    position: absolute;
    bottom: 2.5rem;
    margin-left: 1rem;
}
.header-chat span {
    margin-left: 2rem;
    font-weight: 600;
}

.header-chat img {
    max-width: 2vw;
    max-height: 2vw;
    background-color: var(--white);
    border-radius: 50%;
}

.list-messages {
    width: 60vw;
    height: 75vh;
    overflow-y: scroll; 
    padding-bottom: 5rem; 
    background-color: #e2d8e6
}

.list-messages::-webkit-scrollbar {
    width: 12px;
}

.list-messages::-webkit-scrollbar-track {
    background: #b4a6a6;
}

.list-messages::-webkit-scrollbar-thumb {
    background-color: var(--primary-blue);
    border-radius: 20px;    
}
.send-message-box {
    width: 60vw;
    bottom: 1rem;
    display: flex;
}

.send-message-box textarea {
    width: 55vw;
    margin: 1rem;
    border-radius: .5rem;
    border-color: rgba(0, 0, 0, .15);
    max-height: 3.5rem;
}

.send-button {
    border-radius: .5rem;
    margin: 1rem 0;
    max-height: 4rem;
}

.data-chat {
    margin-left: 2rem;
    font-size: .75rem;
}

@media(max-width: 900px) {
    .user-list {
        display: none;
    } 
    
    .user-list-mobile {
        display: block;
        padding: .5rem;
        border-radius: .5rem;
        margin-bottom: 1rem;
    }

    .chat, .header-chat, .send-message-box {
        width: 85vw;
    }

    .message-container {
        flex-direction: column;
    }

    .list-messages {
        width: 85vw;
        height: 58vh;
        overflow-y: scroll; 
        padding-bottom: 5rem; 
        background-color: #e2d8e6
    }
    
    .add-chat {
        border-radius: 50%;
        position: absolute;
        bottom: 45rem;
        margin-left: -1rem;
    }
    .header-chat img {
        max-width: 10vw;
        max-height: 10vw;
    }

    .send-message-box textarea {
        width: 90vw
    }
}