.leaflet-container {
  height: 85vh;
}

.users-float-list {
  background-color: #FFFFFF;
  box-shadow: 2px 2px 20px rgba(0,0,0, 0.4 );
  margin-top: 2vh;
  border-radius: .5rem;
  left: 21vw;
  text-decoration: none;
  list-style: none;
  width: 30vw;
}

.container-buttons-float {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  right: 0;
  bottom: 0;
}

#input-filter-my-devices {
  width: 100%;
}

.leaflet-marker-icon {
  background: none;
  border: none;
}

.leaflet-marker-icon img {
  width: 5vw;
}

.react-toggle-track-check svg, .react-toggle-track-x svg { 
  display: none;
}

.container-choice-map {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.container-choice-map div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.container-choice-map input {
  border-radius: 100%;
}

.container-choice-map label {
  margin-right: .5rem;
  font-weight: 800;
}

.container-choice-map img {
  width: 25px;
  height: 25px;
}

.container-choice-map button{
  border-radius: .5rem;
}
#change-map {
  position: absolute;
  top: -4rem;
  left: -4rem;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 9vw;
  padding: .5rem 0;
  border-radius: .5rem;
  font-weight: 600;
}

@media(max-width: 768px) {
  .react-draggable {
    width: 60vw;
  }
}