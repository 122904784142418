.message {
  margin: 0 3rem;
  padding: 1rem;
  color: #000;
  border-radius: 8px 8px 0px 8px;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
}

.container-actions-message-buttons {
  margin-left: 47vw;
}

.picture-message {
  width: 40px;
  background-color: #FFFFFF;
  border-radius: 50%;
  position: relative;
  left: -3rem;
  top: 2rem;
}

@media(max-width: 900px) {
  .container-actions-message-buttons {
    margin-left: 1rem;
  }
}
