.container-tables {
    justify-content: space-around;
}

.fast-actions-container {
    position: fixed;
    bottom: 0;
    margin-left: 75vw;
    display: flex;
    flex-direction: column;
}