.button-float-monitor {
    height: 8vh;
    width: 8vh;
    padding: .5rem;
    border-radius: 100%;
    border: none;
    background-color: #F5F5F5;
    box-shadow: 1px 1px 20px rgba(0,0,0, 0.5);
    color: #000000;
    font-weight: 700;
    line-height: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
}


.button-float-monitor  p{
    position: absolute;
    margin-left: -35vw;
    display: none;
    background-color: #FFFFFF;
    padding: 1rem;
    box-shadow: 1px 1px 20px rgba(0,0,0, 0.5);
    border-radius: .5rem;
}

.button-float-monitor:hover  p{
    display: flex;
}

@media(max-width: 768px){
    .button-float-monitor {
        height: 5vh;
        width: 5vh;
        padding: 1.8rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center ;
    }
}