.upload-photo label {
  background-color: rgba(255, 99, 132, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  height: 150px;
  width: 150px;
  padding-top: 10px;
  cursor: pointer;
}
  
.upload-photo {
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
}
  
.line-input {
  min-width: 30vw;
}

@media(max-width: 968px) { 
  .line-input {
    min-width: 20vw;
  } 
}