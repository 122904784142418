
.user {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-evenly;
}

.user:hover {
    background-color: rgba(0,0,0, 0.15);
    cursor: pointer;
}


.user:nth-child(1) {
    margin-top: 1rem;
}

.user img {
    width: 2vw;
    height: 2vw;
    background-color: #FFFFFF;
    border-radius: 50%;
}

.close-button:hover {
    background-color: rgba(0,0,0, 0.55);
}

@media(max-width: 768px) {
    .user img {
        width: 8vw;
        height: 8vw;
    }
    
}