.navBar {
    background-color: var(--primary-blue);
    height: 100vh;
    color: var(--white);
}


.item-navBar {
    text-decoration: none; 
}

.icon-navbar {
    color: var(--white);
}

.text-item-navbar {
    color: var(--white);
    text-decoration: wavy; 
}

.logout-bar {
    background-color: var(--primary-blue);   
}