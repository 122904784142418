.button-float{
    height: 8vh;
    padding: 1.5rem;
    border-radius: 100%;
    border: none;
    background-color: #F5F5F5;
    box-shadow: 1px 1px 20px rgba(0,0,0, 0.5);
    color: #000000;
    font-weight: 700;
    line-height: 1rem;
    margin-bottom: 1rem;
    margin-right: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-float img{
    width: 100%;
}

.button-float p{
    position: absolute;
    display: none;
    background-color: #FFFFFF;
    padding: 1rem;
    box-shadow: 1px 1px 20px rgba(0,0,0, 0.5);
    border-radius: .5rem;
}

.button-float:hover  p{
    display: flex;
}