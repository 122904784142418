.button {
    background-color: var(--primary-blue);
    color: var(--white);
}

.button:hover {
    background-color: var(--secondary-blue);
}

@media(max-width: 768px) {
    .button {
        font-size: .75rem;
    }
}