.quadrado-usuários-totais {
    width: 20px;
    height: 20px;
    border: 1px solid;
    border-color: #fff;
    background-color: rgba(54, 162, 235, 0.2);
}

.quadrado-usuários-inativos {
    width: 20px;
    height: 20px;
    border: 1px solid;
    border-color: #fff;
    background-color: rgba(255, 99, 132, 0.2);
}

.MuiBox-root {
    max-width: 100vw;
}

.reupload-photo-img {
    border-radius: 50%;
    height: 155px;
    width: 155px;
}
/* ------ TOGGLE CSS -----*/
.react-toggle {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
}

.react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #4D4D4D;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
    background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #128D15;
}

.react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
    opacity: 0;
}

.react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4D4D4D;
    border-radius: 50%;
    background-color: #FAFAFA;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
    -moz-box-shadow: 0px 0px 3px 2px #0099E0;
    box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
    -moz-box-shadow: 0px 0px 5px 5px #0099E0;
    box-shadow: 0px 0px 5px 5px #0099E0;
}

.js-startprompt.startprompt h3{
	font-size: 0;
    color: #000; 
}
.js-startprompt.startprompt h3::after {
  content: "Medição de área e distância";
  font-size: 25px !important;
}


.js-startprompt.startprompt .tasks a {
	font-size: 0;
    color: blue; 
}
.js-startprompt.startprompt .tasks a::after {
  content: "Iniciar medição";
  font-size: 16px !important;
  font-weight: 600;
  margin-left: -20px;
}

.js-measuringprompt h3{
	font-size: 0;
    color: #000; 
}
.js-measuringprompt h3::after {
    content: "Medição de área e distância";
    font-size: 25px !important;
}

.js-measuringprompt .js-starthelp{
	font-size: 0;
    color: #000; 
}
.js-measuringprompt .js-starthelp::after {
    content: "Comece a medir adicionando pontos ao mapa";
    font-size: 12px !important;
    line-height: 15px
}

.js-cancel.cancel {
    font-size: 0;
    color: blue; 
}
.js-cancel.cancel::after {
    content: "Cancelar";
    font-size: 12px !important;
    font-weight: 600;
}

.js-cancel.cancel {
    font-size: 0;
    color: blue; 
}
.js-cancel.cancel::after {
    content: "Cancelar";
    font-size: 12px !important;
    font-weight: 600;
}

.js-finish.finish {
    font-size: 0;
    color: blue; 
}
.js-finish.finish::after {
    content: "Finalizar";
    font-size: 12px !important;
    font-weight: 600;
}
@media(max-width: 768px) {
    h4 {
        font-size: 1rem;
    }
}