.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 2rem 1rem;
}

.container-card-info {
  display: flex;
  justify-content: space-between;
}

.card-info {
  padding: 1rem;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.25);
  border-radius: .5rem;
  min-width: 20vw;
}

.card-info p {
  font-size: .75rem;
}

.card-info span {
  font-size: 1.5rem;
 font-weight: 700;
}

.container-filter-reports {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5vh;
}

.container-float-buttons {
  position: absolute;
  right: 0;
}

.report-radio-input {
  margin-right: 1rem;
}

.add-report select, input[type="text"], input[type="datetime-local"], .add-report input[type="email"] {
  width: 100%;
  padding: .25rem;
  border-radius: .5rem;
  border: .5px solid gray;
}

.add-report-config select{
  width: 88%;
}

.add-report-config input[type="text"] {
  width: 120%;
}

li {
  display: flex;
  align-items: center;
}

.menu-report {
  position: absolute;
  z-index: 1;
  max-width: 86vw;
  height: 89%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: .5rem;
  transition: 1s;
}

.menu-icon-button {
  position: absolute;
  margin-top: -4vh;
}

.Dropdown-control {
  width: 25vw;
}

@media(max-width: 768px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
  
}