.container-filter-reports{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5vh;
}

.container-filter-reports li:hover{
    text-decoration: underline;
    cursor: pointer;
}

.card {
    margin: 1rem;
}

@media(max-width: 768px) {
    canvas.nth-child(1) {
        width: 600px;   
    }
}