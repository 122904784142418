

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F5F5;

}

.container-login {
  height: 100vh;
  width: 100vw;
  background-color: #F5F5F5;
  background-image: url("assets/img/banner.png");
  box-shadow: inset 100vw 100vh 5vh rgba(126, 115, 80, 0.5);
  background-repeat: no-repeat;
  background-size: cover;
}

.card-login {
  display: flex;
  flex-direction: row;
  position:  absolute;
  top:  50%;
  left:  50%;
  padding-right: 0;
  transform: translate(-50%, -50%);
  width: 50vw;
  max-width: 90%;
  color: #000;
  border: none;
  box-shadow: 0px 30px 50px rgba(0,0,0, 0.1);
}

.card-content-login {
  width: 50%;
}

.card-content-login:nth-child(2) {
  background-image: url("https://mprastreamento.com.br/mp.jpg");
  background-size: cover;
  background-position: center;
}

.input-login {
  border-radius: 0;
  border: none;
}

#img-logo-login {
  display: none;
}

.form-container {
  padding: .5rem 1.5rem;
}

@media screen and (max-width:  968px) {
  .card-login {
    left: 10%;
    transform:  translate(-5%, -50%);
    width: 90vw;
    
  }

  .card-content-login {
    width: 100%;
    padding: 0;
    margin-left: -.75rem;
  }

  #img-logo-login {
    display: block;
    background-color: var(--primary-blue);
    border-radius: .25rem .25rem 0 0;
    padding: .5rem 0;
  }
  
  .card-content-login:nth-child(2) {
    display: none;
  }
}

p {
  margin: 0;
}

#__react-alert__ div {
  z-index: 1500 !important;
}
